<template>
  <div>
    <v-card id="account-setting-card">
      <!-- tabs -->

      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="(tab, index) in tabs" :key="index">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index" class="ma-10">
          <content-section
            v-if="!loading"
            :title="content.title"
            :html="content.article"
            @title="e => (content.title = e)"
            @html="e => (content.article = e)"
          />
          <v-divider />

          <!-- content.gallery -->
          <render-images :images="content.gallery" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <div class="d-flex justify-space-between align-center my-4">
      <small>Remember to save the changes before switch the language !</small>
      <v-btn @click="save" x-large color="success" dark>
        Save changes
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import ContentSection from "@/components/ContentSection";
import RenderImages from "@/components/RenderImages";

export default {
  components: {
    ContentSection,
    RenderImages
  },

  data() {
    return {
      lng: "en",
      loading: true,
      content: {},
      tab: 0,
      tabs: [
        { title: "English", lng: "en", icon: "mdi-flag-outline" },
        { title: "Polish", lng: "pl", icon: "mdi-flag-outline" },
        { title: "Russian", lng: "ru", icon: "mdi-flag-outline" }
      ]
    };
  },

  watch: {
    tab(e) {
      this.loadData();
    }
  },

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    }),

    getTab() {
      return this.tabs.find((item, index) => index === this.tab);
    },

    baseUrl() {
      return config.mediaBaseUrl;
    }
  },

  methods: {
    ...mapActions({
      getAboutBySlug: "about/getAboutBySlug",
      update: "about/updateById"
    }),

    async loadData() {
      const { lng } = this.getTab;
      const { id } = this.$route.params;

      if (lng) {
        const data = await this.getAboutBySlug({
          lng,
          id
        });

        this.content = JSON.parse(JSON.stringify(data));
        this.loading = false;
      }
    },

    save() {
      try {
        const { lng } = this.getTab;
        const { id } = this.$route.params;

        this.update({
          lng,
          id,
          value: JSON.stringify(this.content)
        });

        this.$toast.success("Content successful updated");
      } catch (error) {
        this.$toast.error(error);
      }
    }
  },

  mounted() {
    this.tab = this.getLanguage.id;
    this.loadData();
  }
};
</script>
